import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FilterService } from 'src/app/shared/services/filter.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ParadeService } from 'src/app/shared/services/parade.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';

@Component({
  selector: 'preview-modal',
  templateUrl: 'preview-modal.html',
})
export class PreviewModalDialogComponent implements AfterViewInit {
  fileId: string = '';
  fileUrl: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<PreviewModalDialogComponent>,
    private sanitizer: DomSanitizer
  ) { }

  ngAfterViewInit(){
    this.fileId = new URL(this.data.parade_presentation.url).pathname.split('/')[3];
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://docs.google.com/presentation/d/" + this.fileId + "/embed?start=false&loop=false&delayms=3000");
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'walkthrough-modal',
  templateUrl: 'walkthrough-modal.html',
})
export class WalkthroughModalDialogComponent {
  fileId: string = '';
  fileUrl: any = '';

  usersList: any = [];

  actionForm = new FormGroup({
    description: new FormControl('', [Validators.required]),
    users: new FormControl('', [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<WalkthroughModalDialogComponent>,
    private sanitizer: DomSanitizer,
    private paradeService: ParadeService,
    private toastrService: ToastrService
  ) { }

  ngAfterViewInit(){
    this.getUsers();
    this.fileId = new URL(this.data.parade.parade_presentation.url).pathname.split('/')[3];
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://docs.google.com/presentation/d/" + this.fileId + "/embed?start=false&loop=false&delayms=3000");
  }

  getUsers(){
    this.paradeService.getUsers().subscribe((res: any) => {
      console.log(res);
      res.forEach((element: any) => {
        // if(this.data?.parade?.parade_people?.findIndex((e: any) => e == element.id) > -1){
          this.usersList.push(element)
        // }
        
      });

      
    })
  }

  createActionTracker(){
    let payload = this.actionForm.value;
    this.paradeService.createAction(this.data.parade.id, payload).subscribe((res: any) => {
      this.actionForm.get('description')?.setValue('')
      this.actionForm.get('users')?.setValue('')
      this.toastrService.success('Action created successfully...')
    })
  }

  onLoadFrame(){
    var iframe: any = document.getElementById("frame");
    if (iframe && iframe?.contentWindow) {
      // iframe.contentWindow.postMessage(event.target.value, "*");
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'app-parade-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  currentYear: any;
  staticText: any = (textConfiguration as any).default;
  type: any;
  paradeImgArray: any = [];
  paradeId: any;

  step: number = 1;
  paradeData: any;

  refreshComment: boolean = false;

  paradeForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    group: new FormControl(''),
    status: new FormControl(''),
    tag_ids: new FormControl([])
  });

  peopleData: any = [];
  responseComment: any = [];

  constructor(private messageService: MessageService, private dialog: MatDialog,
    private activatedRoute: ActivatedRoute, private paradeService: ParadeService,
    private toastrService: ToastrService, private fb: FormBuilder, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.paradeId = atob(params.id);
      this.getParade();
      this.getResponseComment();
    });
  }

  getParade() {
    this.paradeService.getParadeById(this.paradeId).subscribe((res: any) => {
      res.parade_files.forEach((element: any) => {
        element.label = element.name.split('.').slice(0, -1).join('.')
      });
      this.paradeData = res;
      this.paradeForm.patchValue(res);
      this.paradeData.parade_people.forEach((element: any) => {
        this.peopleData.push({ user: element.user.id })
      });
    })
  }


  preview() {
    const dialog = this.dialog.open(PreviewModalDialogComponent, { width: '90vw', height: '90vh', data: this.paradeData });
    dialog.afterClosed().subscribe(data => {
      // this.proceed()
    })
  }

  walkthrough() {
    const dialog = this.dialog.open(WalkthroughModalDialogComponent, { width: '90vw', height: '90vh', data: {
          parade: this.paradeData,
          peopleData: this.peopleData
        }
     });
    dialog.afterClosed().subscribe(data => {
      // this.proceed()
    })
  }

  updateData(value: any) {
    this.peopleData = value;
  }

  updateNameData(value: any) {
    this.paradeForm.get('name')?.setValue(value)
  }

  save() {
    if(this.peopleData.length == 0){
      this.toastrService.warning('People is mandatory');
      return
    }
    const formData = new FormData();
    formData.append('name', this.paradeForm.value.name);
    formData.append('group', this.paradeForm.value.group);
    formData.append('people', this.peopleData.length > 0 ? this.peopleData : []);
    this.spinner.show();
    this.paradeService.updateParade(this.paradeId, formData).subscribe((res: any) => {
      this.spinner.hide();
      this.toastrService.success('Parade updated successfully...')
    })
  }

  async copy(img: any) {
    this.paradeService.downloadImage(img.id).subscribe(async (res: any) => {
      this.toastrService.success('Image copied successfully...')
      let blob = new Blob([res], { type: 'image/png' });
      navigator.clipboard.write([
        new ClipboardItem({
          ['image/png']: blob
        })
      ])
    })
  }

  createPresentation() {
    let payload = {
      parade_id: this.paradeId
    }
    this.toastrService.info("Creating presentation, please wait")
    this.paradeService.createPresentation(payload).subscribe((res: any) => {
      this.toastrService.success('Presentation created successfully...')
      this.getParade();
    })
  }

  getResponseComment() {
    this.paradeService.getResponseComments(this.paradeId).subscribe((res: any) => {
      this.responseComment = res;
    })
  }

  receiveDataFromComment(event: any) {
    this.spinner.show();
    this.refreshComment = false;
    const formData = new FormData();
    if (event?.text || event?.document) {
      if (event.text)
        formData.append('text', event.text);
      if (event.document) {
        formData.append('attachment', event.document);
      }
      formData.append('parade_id', this.paradeId);

      this.paradeService.postComments(this.paradeId, formData).subscribe((res: any) => {
        this.spinner.hide();
        this.toastrService.show("Comments added successfully...")
        this.responseComment.push(res);
        this.refreshComment = true;
      })
    }
  }

  downloadCommentFile(event: any) {
    
  }

  copyBlob(event: any){
    this.toastrService.success('Image copied successfully...')
    // if (document.hasFocus()) {
    //   navigator.clipboard.write([new ClipboardItem({'image/png': event})])
    // } else {
    //   window.addEventListener('focus', function onWindowFocus() {
    //     window.removeEventListener('focus', onWindowFocus);
    //     navigator.clipboard.write([new ClipboardItem({'image/png': event})])
    //   });
    // }
  }

  getTagObject(){
    return {
      entity_id: this.paradeId,
      entity_type: "parade"
    }
  }
}
