<app-modal-header-common [title]="'Walkthrough'"></app-modal-header-common>
<div style="padding: 20px; font-size: 13px" *ngIf="data">
  <div style="display: flex">
    <div style="width: 70%">
      <iframe
        *ngIf="fileId"
        [src]="fileUrl"
        frameborder="0"
        width="100%"
        style="height: 70vh"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        (load)="onLoadFrame()"
        id="frame"
      ></iframe>
    </div>
    <div style="width: 30%; padding: 0 15px">
      <form [formGroup]="actionForm">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Takeaway Notes</mat-label>
          <textarea matInput rows="10" formControlName="description"></textarea>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Assignee</mat-label>
          <mat-select formControlName="users" multiple>
            <ng-container *ngFor="let x of usersList">
              <mat-option *ngIf="x?.first_name || x?.last_name" [value]="x.id"
                >{{x?.first_name}} {{x?.last_name}}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>

        <div class="text-right mt-3">
          <button
            mat-flat-button
            mat-button
            color="primary"
            class="bg-primary text-white"
            [disabled]="actionForm.invalid"
            (click)="createActionTracker()"
          >
            Create Action
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
