import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetailComponent } from './detail/detail.component';
import { ParadeService } from 'src/app/shared/services/parade.service';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: { breadcrumb: 'Parade' },
    children: [
      {
        path: '',
        data: { breadcrumb: '' },
        component: DashboardComponent
      },
      {
        path: 'detail/:id',
        component: DetailComponent,
        data: { breadcrumb: (data: any) => `${data?.parade?.name}`},
        resolve: { parade: ParadeService },
      },

      
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParadeRoutingModule { }
