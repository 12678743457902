<div class="w-100 bg-lavendar mt-80">
  <app-header></app-header>
</div>
<div class="bg-color p-3">
  <div class="px-0 pb-3">
    <app-bread-crumb></app-bread-crumb>
  </div>
  <div class="row">
    <div class="col-sm-2">
      <div class="single-left-pane">
        <app-side-menu></app-side-menu>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="single-center-pane">
        <app-loading *ngIf="!paradeData"></app-loading>
        <div *ngIf="paradeData">
          <form [formGroup]="paradeForm">
            <h5 class="title-bh">
              <app-label-input
                [inputString]="paradeForm.value.name"
                [placeholder]="'Enter Parade Name'"
                (refreshData)="updateNameData($event)"
              ></app-label-input>
              <span>
                <button
                  mat-stroked-button
                  color="primary"
                  class="mr-1"
                  (click)="save()"
                >
                  Save
                </button>
                <button
                  mat-flat-button
                  mat-button
                  color="primary"
                  class="bg-primary text-white mx-1"
                  (click)="preview()"
                >
                  Preview
                </button>
                <button
                  mat-flat-button
                  mat-button
                  color="primary"
                  class="bg-primary text-white ml-1"
                  (click)="walkthrough()"
                >
                  Walkthrough
                </button>
              </span>
            </h5>
            <app-tag
              [dataObj]="getTagObject()"
              [tagsIds]="paradeForm?.value?.tag_ids"
            ></app-tag>
            <p
              class="m-0 grpa mt-3"
              style="display: flex; align-items: center; font-size: 12px"
            >
              <mat-form-field class="w-30" appearance="outline">
                <mat-label>Group</mat-label>
                <input matInput formControlName="group" />
              </mat-form-field>
              <span
                class="mx-1"
                style="font-weight: 600; margin-left: 40px !important"
                >People</span
              >
              <app-owner
                *ngIf="peopleData"
                [editable]="true"
                (refereshParentData)="updateData($event, 'people')"
                [ownerdata]="peopleData"
              ></app-owner>
            </p>
            <div class="row mb-3">
              <div class="col-sm-1 label"></div>
              <div class="col-sm-2 value"></div>
            </div>

            <div class="frameh">
              <div class="img-array">
                <h6 class="m-0">Carousel Drops</h6>
                <p class="m-0" style="font-size: 10px">
                  Click image to copy and use it in your slide
                </p>
                <div
                  *ngIf="paradeData.parade_files.length > 0"
                  class="nsq mt-3"
                >
                  <div
                    *ngFor="let img of paradeData.parade_files; let i = index"
                    class="nsq2 mgh"
                  >
                    <p class="m-0 text-center">{{ img.label }}</p>
                    <img
                      src="{{ img.url }}"
                      class="imgitem"
                      id="imageElement{{ i }}"
                    />
                    <div class="ngq">
                      <button
                        mat-flat-button
                        color="primary"
                        (click)="copy(img)"
                      >
                        Copy
                      </button>
                    </div>
                    <div class="shw">
                      <p>{{ img.label }}</p>
                      <img src="{{ img.url }}" class="imgzoom" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mx-2"
                style="
                  width: 100%;
                  height: calc(100vh - 240px);
                  width: 85%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 0.5px solid rgb(192, 188, 188);
                "
                *ngIf="!paradeData.parade_presentation"
              >
                <div>
                  <button
                    mat-flat-button
                    color="primary"
                    (click)="createPresentation()"
                  >
                    Create Presentation
                  </button>
                </div>
              </div>
              <div
                class="px-2"
                style="width: 85%"
                *ngIf="paradeData.parade_presentation"
              >
                <ngx-doc-viewer
                  [url]="paradeData?.parade_presentation?.url"
                  viewer="url"
                  style="
                    width: 100%;
                    height: calc(100vh - 240px);
                    border: 0.5px solid rgb(192, 188, 188);
                  "
                >
                </ngx-doc-viewer>
              </div>
            </div>
          </form>
        </div>
        <div class="p-3">
          <app-comments
            (dataEvent)="receiveDataFromComment($event)"
            (copyFileEvent)="copyBlob($event)"
            (downloadFileEvent)="downloadCommentFile($event)"
            [comments]="responseComment"
            [refresh]="refreshComment"
            [copyAllowed]="true"
          ></app-comments>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
