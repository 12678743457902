import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilterService } from 'src/app/shared/services/filter.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ParadeService } from 'src/app/shared/services/parade.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  parade: any;
  loading: false;
  paradeCreated: any;
  paradeCompleted: any;
  user: any;

  subscription: any;
  filterObj: any;
  tagFilterId:any;
    
  constructor(private router: Router, private paradeService: ParadeService, 
    private filterService: FilterService,
    private messageService: MessageService,
    private toastrService: ToastrService) { }
  

  ngOnInit(): void {
    this.messageService.setGnews('Parade listing');    
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');

    this.subscription = this.filterService.filterSubject.subscribe((res: any) => {
      this.filterObj = res;
      this.tagFilterId=[];
      Object.keys(this.filterObj).forEach((element: any) => {
        if(element == 'tag'){
          console.log(this.filterObj[element]);
          this.filterObj[element]?.array?.forEach((tag:any)=>{
            this.tagFilterId.push(tag?.id);
          })
        };
      });
      this.getParades();
    })
  }

  getParades(){
    this.paradeService.getParade(this.tagFilterId).subscribe((res: any) => {
      res.forEach((element: any) => {
        element.color = this.getGMapRandomColor(),
        element.last_updated_on = new Date(element.last_updated_on)
      });
     this.parade = {
        own: res.filter((e: any) => e.created_by.id === this.user.id),
        other: res.filter((e: any) => e.created_by.id !== this.user.id)
     };
     this.paradeCreated = {
      own: res.filter((e: any) => e.created_by.id === this.user.id && e.status == 'created'),
      other: res.filter((e: any) => e.created_by.id !== this.user.id && e.status == 'created')
     };

     this.paradeCompleted = {
      own: res.filter((e: any) => e.created_by.id === this.user.id && e.status == 'Completed'),
      other: res.filter((e: any) => e.created_by.id !== this.user.id && e.status == 'Completed')
     }
    })
  }

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }

  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 30%, 40%, 100)';
  } 

  openDetail(x: any){
    this.router.navigateByUrl('parade/detail/'+btoa(x.id + ''))
  }

  deleteParade(x: any, i: any){
    this.paradeService.deleteParade(x).subscribe((res: any) => {
      this.parade.own.splice(i, 1)
      this.toastrService.success('Parade deleted successfully...')
    })
  }

}
