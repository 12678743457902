<div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
  </div>
  <div class="bg-color p-3">

<div class="px-0 pb-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
      <div class="single-left-pane">
          <app-side-menu></app-side-menu>
      </div>
  </div>
  <div class="col-sm-7">
      <div class="single-center-pane">
        <div class="row">
            <div class="col-sm-6 trn-trc">
                <h5>Parade </h5>
            </div>
            <div class="col-sm-6" style="display: flex; justify-content: end">
                <app-filter-card [pagetype]="'parade'"></app-filter-card>
            </div>
        </div>
        <mat-tab-group mat-align-tabs="start">
            <mat-tab label="All ({{parade?.own?.length + parade?.other?.length}})">
                <div style="overflow-x: hidden;">
                    <div class="mt-4">
                        <h6>Parades created by you</h6>
                        <div class="flex-container-1">
                            <div *ngIf="parade?.own && parade?.own?.length <= 0 " class="trn-no">
                                You haven't created any parade.
                            </div>
        
                            <app-loading style="width: 100%; min-height: 200px;" *ngIf="!parade"></app-loading>
        
                            <div class="flex-item p-1" *ngFor="let x of parade?.own">
                                <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                                    <h6 class="mb-0 trn-nm">{{x.name}}</h6>
                                    <p class="mb-2" style="font-size: 9px; margin-top: 20px;">Created by <span style="display: block; width: 100%; font-weight: 700; font-size: 11px">{{x.created_by.first_name}} {{x.created_by.last_name}}</span></p>
                                    <div class="st-col" [ngStyle]="{'background': x.status == 'Created' ? '#d3c438' :  x.status == 'Completed' ? 'green' : 'orange'}">
                                        {{x.status}}</div>
                                    
                                </div>
                                <div class="dos">
                                    <span style="font-size: 10px">Updated {{x.last_updated_on | timeAgo}}</span>
        
                                </div>

                                <button class="action-vert" color="warn" (click)="deleteParade(x.id, i); $event.stopPropagation()" mat-icon-button>
                                    <mat-icon style="color: red;">delete</mat-icon>
                                </button>

                                <!-- <button mat-icon-button color="primary" [mat-menu-trigger-for]="actionmenu" class="action-vert" (click)="$event.stopPropagation()">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #actionmenu="matMenu">
                                    <button mat-menu-item  (click)="deleteParade(x.id, i)">
                                        <span>Delete Parade</span>
                                    </button>
                                </mat-menu> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow-x: hidden;">
                    <div class="mt-4">
                        <h6>Parades you are collabrating on</h6>
                        <div class="flex-container-1">
                            <div *ngIf="parade?.other && parade?.other?.length <= 0 " class="trn-no">
                                You haven't collabrating on any parade
                            </div>
        
                            <app-loading style="width: 100%; min-height: 200px;" *ngIf="!parade"></app-loading>
        
                            <div class="flex-item p-1" *ngFor="let x of parade?.other">
                                <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                                    <h6 class="mb-0 trn-nm">{{x.name}}</h6>
                                    <p class="mb-2" style="font-size: 9px; margin-top: 20px;">Created by <span style="display: block; width: 100%; font-weight: 700; font-size: 11px">{{x.created_by.first_name}} {{x.created_by.last_name}}</span></p>
                                    <div class="st-col" [ngStyle]="{'background': x.status == 'Created' ? '#d3c438' :  x.status == 'Completed' ? 'green' : 'orange'}">
                                        {{x.status}}</div>
                                    
                                </div>
                                <div class="dos">
                                    <span style="font-size: 10px">Updated {{x.last_updated_on | timeAgo}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Created ({{paradeCreated?.own?.length + paradeCreated?.other?.length}})">
                <div style="overflow-x: hidden;">
                    <div class="mt-4">
                        <h6>Parade created by you </h6>
                        <div class="flex-container-1">
                            <div *ngIf="paradeCreated?.own && paradeCreated?.own?.length <= 0 " class="trn-no">
                                You haven't created any parade.
                            </div>
        
                            <app-loading style="width: 100%; min-height: 200px;" *ngIf="!paradeCreated"></app-loading>
        
                            <div class="flex-item p-1" *ngFor="let x of paradeCreated?.own">
                                <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                                    <h6 class="mb-0 trn-nm">{{x.name}}</h6>
                                    <p class="mb-2" style="font-size: 9px; margin-top: 20px;">Created by <span style="display: block; width: 100%; font-weight: 700; font-size: 11px">{{x.created_by.first_name}} {{x.created_by.last_name}}</span></p>
                                    <div class="st-col" [ngStyle]="{'background': x.status == 'Created' ? '#d3c438' :  x.status == 'Completed' ? 'green' : 'orange'}">
                                        {{x.status}}</div>
                                    
                                </div>
                                <div class="dos">
                                    <span style="font-size: 10px">Updated {{x.last_updated_on | timeAgo}}</span>
        
                                </div>
                                <button class="action-vert" color="warn" (click)="deleteParade(x.id, i); $event.stopPropagation()" mat-icon-button>
                                    <mat-icon style="color: red;">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow-x: hidden;">
                    <div class="mt-4">
                        <h6>Parade you are collabrating on </h6>
                        <div class="flex-container-1">
                            <div *ngIf="paradeCreated?.other && paradeCreated?.other?.length <= 0 " class="trn-no">
                                You haven't collabrating on any parade
                            </div>
        
                            <app-loading style="width: 100%; min-height: 200px;" *ngIf="!paradeCreated"></app-loading>
        
                            <div class="flex-item p-1" *ngFor="let x of paradeCreated?.other">
                                <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                                    <h6 class="mb-0 trn-nm">{{x.name}}</h6>
                                    <p class="mb-2" style="font-size: 9px; margin-top: 20px;">Created by <span style="display: block; width: 100%; font-weight: 700; font-size: 11px">{{x.created_by.first_name}} {{x.created_by.last_name}}</span></p>
                                    <div class="st-col" [ngStyle]="{'background': x.status == 'Created' ? '#d3c438' :  x.status == 'Completed' ? 'green' : 'orange'}">
                                        {{x.status}}</div>
                                    
                                </div>
                                <div class="dos">
                                    <span style="font-size: 10px">Updated {{x.last_updated_on | timeAgo}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Completed ({{paradeCompleted?.own?.length + paradeCompleted?.other?.length}})">
                <div style="overflow-x: hidden;">
                    <div class="mt-4">
                        <h6>Parade created by you </h6>
                        <div class="flex-container-1">
                            <div *ngIf="paradeCompleted?.own && paradeCompleted?.own?.length <= 0 " class="trn-no">
                                You haven't created any parade.
                            </div>
        
                            <app-loading style="width: 100%; min-height: 200px;" *ngIf="!paradeCompleted"></app-loading>
        
                            <div class="flex-item p-1" *ngFor="let x of paradeCompleted?.own">
                                <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                                    <h6 class="mb-0 trn-nm">{{x.name}}</h6>
                                    <p class="mb-2" style="font-size: 9px; margin-top: 20px;">Created by <span style="display: block; width: 100%; font-weight: 700; font-size: 11px">{{x.created_by.first_name}} {{x.created_by.last_name}}</span></p>
                                    <div class="st-col" [ngStyle]="{'background': x.status == 'Created' ? '#d3c438' :  x.status == 'Completed' ? 'green' : 'orange'}">
                                        {{x.status}}</div>
                                    
                                </div>
                                <div class="dos">
                                    <span style="font-size: 10px">Updated {{x.last_updated_on | timeAgo}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow-x: hidden;">
                    <div class="mt-4">
                        <h6>Parade you are collabrating on </h6>
                        <div class="flex-container-1">
                            <div *ngIf="paradeCompleted?.other && paradeCompleted?.other?.length <= 0 " class="trn-no">
                                You haven't collabrating on any parade
                            </div>
        
                            <app-loading style="width: 100%; min-height: 200px;" *ngIf="!paradeCompleted"></app-loading>
        
                            <div class="flex-item p-1" *ngFor="let x of paradeCompleted?.other">
                                <div class="trn-x" [ngStyle]="{'background': x.color}" (click)="openDetail(x)">
                                    <h6 class="mb-0 trn-nm">{{x.name}}</h6>
                                    <p class="mb-2" style="font-size: 9px; margin-top: 20px;">Created by <span style="display: block; width: 100%; font-weight: 700; font-size: 11px">{{x.created_by.first_name}} {{x.created_by.last_name}}</span></p>
                                    <div class="st-col" [ngStyle]="{'background': x.status == 'Created' ? '#d3c438' :  x.status == 'Completed' ? 'green' : 'orange'}">
                                        {{x.status}}</div>
                                    
                                </div>
                                <div class="dos">
                                    <span style="font-size: 10px">Updated {{x.last_updated_on | timeAgo}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        
          
          
      </div>
  </div>
  <div class="col-sm-3">      
    <div class="single-right-pane ">
        <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
        <app-gnews></app-gnews>
        <!-- <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                    style="font-size: 13px">
                    Curated from Web
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body p-2">
                    <app-loading *ngIf="loading"></app-loading>
                    <app-gnews [display_header]="'false'"></app-gnews>
                </div>
            </div>
        </div>    -->
    </div>
  </div>
</div>

  </div>
  <app-footer></app-footer>
  